.tickets-page {
  padding: 1.5rem;

  .page-title {
    color: #495057;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .stats-card {
    background: #fff;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-2px);
    }

    .card-body {
      display: flex;
      align-items: center;
      padding: 1.5rem;
    }

    .stats-icon {
      width: 48px;
      height: 48px;
      background: rgba(232, 92, 65, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1rem;

      svg {
        color: #e85c41;
        font-size: 1.5rem;
      }
    }

    .stats-info {
      h3 {
        font-size: 0.875rem;
        color: #6c757d;
        margin: 0 0 0.5rem 0;
      }

      p {
        font-size: 1.5rem;
        font-weight: 600;
        color: #495057;
        margin: 0;
      }
    }
  }

  .search-box {
    position: relative;

    input {
      padding-left: 2.5rem;
      border-radius: 4px;
      border: 1px solid #ced4da;
    }

    .search-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #6c757d;
    }
  }

  .btn-group {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .tickets-table {
    th, td {
      vertical-align: middle;
    }

    th {
      background-color: #f8f9fa;
      border-bottom: 2px solid #dee2e6;
      color: #495057;
      font-weight: 600;
      white-space: nowrap;
    }

    td {
      color: #495057;
    }

    .badge {
      font-size: 0.8rem;
      padding: 0.4em 0.8em;
    }

    .progress {
      height: 0.75rem;
      border-radius: 0.5rem;
      background-color: #e9ecef;

      .progress-bar {
        border-radius: 0.5rem;
        font-size: 0.75rem;
        font-weight: 600;
      }
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }

      &:hover {
        color: #e85c41;
      }
    }

    .dropdown-menu {
      min-width: 150px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);

      .dropdown-item {
        padding: 0.5rem 1rem;
        
        &:hover {
          background-color: #f8f9fa;
        }

        &.text-danger:hover {
          background-color: #fff5f5;
        }
      }
    }
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .modal-body {
    h5 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: #495057;
    }

    p {
      margin-bottom: 0.5rem;
      
      strong {
        color: #6c757d;
        margin-right: 0.5rem;
      }
    }
  }

  // Advanced filters
  .form-group {
    margin-bottom: 1rem;

    label {
      font-weight: 500;
      color: #495057;
      margin-bottom: 0.5rem;
    }

    select.form-control {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .ticket-card {
    margin-bottom: 1.5rem; // Increased margin to create more space for dropdown
    position: relative;
    z-index: 1;
    transition: transform 0.2s ease-in-out;
    border: none;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    }

    .card-header {
      background-color: transparent;
      border-bottom: 1px solid rgba(0,0,0,0.1);
      padding: 1rem;

      h5 {
        font-weight: 600;
        color: #2c3e50;
        margin: 0;
      }
    }

    .card-body {
      padding: 1rem;
    }

    .card-footer {
      background-color: transparent;
      border-top: 1px solid rgba(0,0,0,0.1);
      padding: 0.75rem 1rem;
    }


    .ticket-type-badge {
      font-weight: 500;
      padding: 0.5rem 1rem;
      border-radius: 4px;
    }

    .dropdown-menu {
      z-index: 1050;
    }
  }

  .search-box {
    position: relative;
    
    .search-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #6c757d;
    }
  }

  .react-datepicker-wrapper {
    width: auto;
    flex: 1;
    
    input {
      width: 100%;
    }
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .date-picker-container {
    display: flex;
    gap: 10px;
  }

  .bg-success-light {
    background-color: #d7f6a8 !important;
    color: #fff !important;
  }
}


.ticket-details {
  .detail-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    
    .detail-badge {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 0.75rem;
      border-radius: 20px;
      background-color: #f8f9fa;
      color: #495057;
      border: 1px solid #e9ecef;
      font-size: 0.75rem;
      
      .svg-inline--fa {
        color: #6c757d;
      }

      &:hover {
        background-color: #e9ecef;
      }
    }
  }
}