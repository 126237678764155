.settings-page {
  padding: 20px;

  .settings-card {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    
    .card-header {
      background-color: #f8f9fa;
      border-bottom: 1px solid #dee2e6;
      
      h3 {
        margin: 0;
        font-size: 1.25rem;
        color: #495057;
      }
    }
  }

  .ticket-type-row {
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;

    &:last-child {
      border-bottom: none;
    }
  }

  .new-ticket-type {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 0.25rem;

    h4 {
      color: #495057;
    }
  }

  .form-control-color {
    height: 38px;
    padding: 0.375rem;
  }

  .button-group {
    display: flex;
    align-items: center;
  }

  .toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .button-group {
      margin-top: 1rem;
      justify-content: flex-start;
    }
  }
}
