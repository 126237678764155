input,
select {
  padding: 8px;
  border: 1px solid #666;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 0.75rem;

  &:focus {
    border-color: #66afe9;
    outline: none;
  }
}

select {
  appearance: none;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border-color: #ff7f29;
  }

  option {
    background: white;
  }
}

.form-group {
  margin-bottom: 0.75rem;
  
  label {
    font-size: 14px;
    margin-bottom: 0.25rem;
  }
}

.input-group-text {
  padding: 8px;
  
  svg {
    opacity: 0.6;
    font-size: 14px;
  }
}
