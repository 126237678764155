.users-page {
  padding: 1.5rem;

  .page-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: #495057;
    font-weight: 600;
  }

  .table {
    thead {
      th {
        background-color: #f8f9fa;
        color: #495057;
        font-weight: 600;
        vertical-align: middle;
        padding: 12px;
      }
    }

    td {
      color: #6c757d;
      vertical-align: middle;
      padding: 12px;

      // Action buttons styling
      .btn-sm {
        display: inline-flex;
        align-items: center;
        margin-right: 5px;
      }
    }
  }

  // Permissions badge styling
  .badge {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;

    &.badge-success {
      background-color: #28a745;
    }

    &.badge-secondary {
      background-color: #6c757d;
    }
  }

  // Modal styling
  .modal-header {
    display: flex;
    align-items: center;

    .modal-title {
      display: flex;
      align-items: center;
    }
  }

  .form-group {
    margin-bottom: 15px;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    // Permissions buttons in modal
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-flex;
      align-items: center;
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .page-title {
      flex-direction: column;
      align-items: flex-start;

      .btn {
        margin-top: 10px;
      }
    }

    .table {
      td, th {
        padding: 8px;
      }
    }
  }
}
