@use "sass:math";

// Import variables
@import "../../themes/default/variables";

// Sidebar variables with defaults
$app-sidebar-width: 280px !default;
$app-header-height: 60px !default;
$layout-spacer-x: 1.5rem !default;
$font-size-xs: 12px !default;
$primary: #337ab7 !default;

.app-sidebar {
  width: $app-sidebar-width;
  display: flex;
  z-index: 11;
  overflow: hidden;
  min-width: $app-sidebar-width;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  flex: 0 0 $app-sidebar-width;
  margin-top: 0;
  padding-top: $app-header-height;
  height: 100vh;
  transition: all .2s;

  .app-sidebar__inner {
    padding: 2px ($layout-spacer-x) $layout-spacer-x;
    overflow-y: auto;
    width: 100%;
  }

  .scrollbar-container {
    z-index: 15;
    width: 100%;
    height: 100%;
  }

  .app-sidebar-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0.05;
    background-size: cover;
    z-index: 10;
  }

  .app-header__logo {
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 11;
  }

  &.sidebar-shadow {
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05);
  }
}

.app-sidebar__heading {
  text-transform: uppercase;
  font-size: $font-size-xs;
  margin: math.div($layout-spacer-x, 2) 0;
  font-weight: bold;
  color: $primary;
  white-space: nowrap;
  position: relative;
}

.sidebar-mobile-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #333;
  opacity: .6;
  left: 0;
  top: 0;
  z-index: 12;
}

// Sidebar Components
@import "modifiers/navmenu-vertical";

// Sidebar Modifiers
@import "themes/sidebar-light";
@import "themes/sidebar-dark";

@import "modifiers/fixed-sidebar";
@import "modifiers/closed-sidebar";
