.city-selection-page {
  padding: 0rem 0;
  background-color: #f8f9fa;
  min-height: 100vh;

  h1 {
    color: #333;
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2rem;
  }

  .city-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 1rem 0;
  }

  .city-card {
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.3s ease;
    border: none;
    border-radius: 12px;
    overflow: hidden;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      background-color: rgba(232, 92, 65, 0.1); // Increased color intensity
    }

    .city-image-container {
      position: relative;
      padding-top: 66.67%; // 3:2 aspect ratio
      overflow: hidden;

      .city-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }
    }

    &:hover .city-image {
      transform: scale(1.05);
    }

    .card-body {
      padding: 1rem;

      h3 {
        margin: 0;
        font-size: 1.25rem;
        font-weight: 600;
        color: #333;
      }

      .date {
        margin-top: 0.5rem;
        color: #666;
        font-size: 0.9rem;
        display: flex;
        align-items: center;

        i {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .city-selection-page {
    h1 {
      font-size: 1.5rem;
      padding: 0 1rem;
    }

    .city-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 1rem;
      padding: 1rem;
    }
  }
}
