.app-page-title {
  padding: 24px 30px;
  // margin: 0;
  position: relative;
  background: #f8fafc;
  border-bottom: 1px solid #edf2f7;

  .page-title-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    overflow: hidden;

    .page-title-heading {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      
      .page-title-icon {
        font-size: 1.5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        margin-right: 1rem;
        width: 46px;
        height: 46px;
        color: #e85c41;
        background: rgba(232, 92, 65, 0.08);
        border-radius: 10px;
        transition: all 0.2s;

        svg {
          font-size: 1.25rem;
        }

        &:hover {
          transform: translateY(-2px);
        }
      }

      .page-title-text {
        display: inline-block;
        
        .page-title-subheading {
          font-size: 0.9rem;
          color: #64748b;
          font-weight: 400;
          margin: 5px 0 0;
        }
      }
    }

    .page-title-actions {
      margin-left: auto;
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .app-page-title {
    padding: 20px;

    .page-title-wrapper {
      flex-direction: column;
      align-items: flex-start;

      .page-title-actions {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
}
