.city-dashboard {
  .page-title {
    color: #495057;
    font-weight: 600;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    svg {
      color: #e85c41;
      margin-right: 0.75rem;
    }
  }

  .city-stats-card {
    position: relative;
    overflow: hidden;
    border: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }

    .city-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      opacity: 0.1;
      z-index: 1;
      filter: grayscale(50%);
    }

    .card-body {
      position: relative;
      z-index: 2;
      background: transparent;
    }

    .stats-section {
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 8px;
      padding: 1rem;
      margin-top: 1rem;
    }

    .ticket-type-breakdown {
      .progress {
        height: 0.5rem;
        border-radius: 10px;
      }

      .text-capitalize {
        text-transform: capitalize;
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .city-stats-card {
      margin-bottom: 1rem;
    }
  }
}
