@use "sass:math";

// Import variables
@import "../themes/default/variables";

// Layout adjustments for fixed sidebar
$app-sidebar-width: 280px !default;
$app-header-height: 60px !default;
$app-footer-height: 50px !default;
$layout-spacer-x: 1.5rem !default;

.app-main {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &.public-page {
    .app-main__outer {
      width: 100%;
      margin-left: 0;
    }
  }

  .app-main__outer {
    flex-grow: 1;
    margin-left: $app-sidebar-width;
    width: calc(100% - #{$app-sidebar-width});
    height: 100vh;
    overflow-y: auto;
    transition: margin-left 0.2s, width 0.2s;
  }

}

// Responsive adjustments
@media (max-width: 991.98px) {
  .app-main {
    .app-main__outer {
      margin-left: 0;
      width: 100%;
    }
  }
}
