.vertical-nav {
  padding: 0.5rem;
  background: white;
  width: 100%;

  .nav-item {
    width: 100%;
    margin: 0.35rem 0;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      background-color: #f8f9fa;
      transform: translateX(5px);
    }

    &.active {
      background: linear-gradient(45deg, #e85c41, #ff7259);
      
      .nav-link {
        color: white !important;
        font-weight: 600;
      }
    }

    .nav-link {
      width: 100%;
      padding: 0.85rem 1.2rem;
      color: #4a5568;
      font-size: 0.95rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      transition: all 0.2s ease;
      text-decoration: none;

      i, svg {
        margin-right: 12px;
        font-size: 1.1rem;
        min-width: 24px;
        opacity: 0.8;
      }

      &:hover {
        color: #e85c41;
      }
    }
  }
}

.app-sidebar__heading {
  padding: 1.2rem 1.5rem 1rem;
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  color: #718096;
  margin: 0;
  border-bottom: 1px solid #edf2f7;
  background: white;
}
