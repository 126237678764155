.footer {
  background-color: #f8f9fa;
  padding: 3rem 0;
  margin-top: 2rem;
  border-top: 1px solid #dee2e6;
  color: #6c757d;

  .footer-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;

    .logo-section {
      display: flex;
      align-items: center;
      gap: 2rem;

      img {
        height: 100px;
        object-fit: contain;
      }
    }

    .social-links {
      display: flex;
      gap: 1rem;

      a {
        color: #6c757d;
        font-size: 1.5rem;
        transition: color 0.3s ease;

        &:hover {
          color: #0d6efd;
        }
      }
    }
  }

  .footer-middle {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;

    .footer-links {
      h5 {
        color: #495057;
        margin-bottom: 1rem;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 0.5rem;

          a {
            color: #6c757d;
            text-decoration: none;
            transition: color 0.3s ease;

            &:hover {
              color: #0d6efd;
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    text-align: center;
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #dee2e6;
    color: #6c757d;

    p {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    .footer-top, .footer-middle {
      flex-direction: column;
      text-align: center;

      .logo-section {
        justify-content: center;
      }

      .social-links {
        justify-content: center;
      }
    }
  }
}
