.register-page {
  padding: 2rem 0;
  background: 
    linear-gradient(120deg, rgba(255, 127, 41, 0.05) 0%, rgba(255, 107, 0, 0.05) 100%),
    radial-gradient(circle at 50% 0%, rgba(255, 127, 41, 0.1) 0%, transparent 50%),
    linear-gradient(60deg, #ffffff 0%, #fff5ee 100%);
  background-attachment: fixed;
  min-height: 100vh;

  h1 {
    color: #d35400;
    font-weight: 700;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  }

  .card {
    border-radius: 12px;
    border: none;
    box-shadow: 
      0 4px 15px rgba(255, 127, 41, 0.08),
      inset 0 -4px 0 rgba(255, 127, 41, 0.2);
    margin-bottom: 1.5rem;
    backdrop-filter: blur(10px);
    background: linear-gradient(to right, #ffffff, #fff8f3);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: linear-gradient(to bottom, #ff7f29, #ff6b00);
    }

    .card-body {
      padding: 1.5rem;
    }
  }

  .registration-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, 
        rgba(255, 127, 41, 0.3) 0%,
        rgba(255, 127, 41, 0.1) 50%,
        rgba(255, 127, 41, 0.05) 100%
      );
    }

    h2 {
      margin: 0;
      margin-left: 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      color: #ff7f29;
      background: linear-gradient(to right, #ff7f29, #ff6b00);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    svg {
      font-size: 1.6rem;
      color: rgba(255, 127, 41, 0.8);
    }
  }

  .registration-subtitle {
    color: #6c757d;
    margin-bottom: 2.5rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }

  .participant-card {
    background: #fff;
    margin: 2rem 0;
    border-radius: 15px;
    border: 1px solid #f0f2f5;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.03);
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: linear-gradient(90deg, #d35400, #f39c12);
    }

    .participant-header {
      color: #d35400;
      font-size: 1.4rem;
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      border-bottom: 1px solid #f0f2f5;

      svg {
        margin-right: 1rem;
        color: #d35400;
      }
    }

    .card-body {
      padding: 1.5rem 2rem;
    }
  }

  .section-header {
  //   display: flex;
  //   align-items: center;
  //   margin-bottom: 1.2rem;
    font-weight: 600;
  //   color: #ff7f29;

    svg {
      margin-right: 1rem;
      color: #d35400;
      font-size: 1.2rem;
    }
  }

  .input-icon {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    svg {
      color: #ffb889;
      margin-right: 0.5rem;
      font-size: 1.1rem;
    }

    label {
      color: #495057;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  .form-control, .form-select {
    border-radius: 10px;
    border: 1px solid #a9a9a9;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, #ffffff, #fff8f3);
  }

  .form-select {
    background: 
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") no-repeat right 1rem center/16px 12px,
      linear-gradient(135deg, #ffffff, #fff8f3);
  }

  .form-control:focus, .form-select:focus {
    border-color: #ff7f29;
    box-shadow: 0 0 0 0.2rem rgba(255, 127, 41, 0.15);
    background: #ffffff;
  }

  .form-control::placeholder, .form-select::placeholder {
    color: #adb5bd;
  }

  .add-participant-btn {
    color: #d35400;
    padding: 1rem 1.5rem;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    border: 2px dashed rgba(211, 84, 0, 0.3);
    border-radius: 12px;
    transition: all 0.3s ease;
    margin: 1.5rem 0;
    background: rgba(211, 84, 0, 0.02);

    &:hover {
      color: #c65d00;
      background: rgba(211, 84, 0, 0.05);
      border-color: rgba(211, 84, 0, 0.5);
      text-decoration: none;
      transform: translateY(-2px);
    }

    svg {
      margin-right: 1rem;
      font-size: 1.2rem;
    }
  }

  .referral-section {
    margin-top: 2.5rem;
    padding: 2rem;
    background: #f8f9fa;
    border-radius: 15px;
    border: 1px solid #e9ecef;
    
    label {
      color: #d35400;
      font-weight: 600;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1rem;
        color: #d35400;
      }
    }
  }

  .submit-section {
    margin-top: 3rem;
    text-align: center;

    .btn-primary {
      background: linear-gradient(135deg, #ff7f29, #ff6b00);
      border: none;
      padding: 1rem 3rem;
      font-weight: 600;
      font-size: 1.1rem;
      border-radius: 50px;
      box-shadow: 0 4px 15px rgba(255, 127, 41, 0.2);
      transition: all 0.3s ease;

      &:hover {
        background: linear-gradient(135deg, #ff8d3d, #ff7f29);
        transform: translateY(-1px);
        box-shadow: 0 6px 18px rgba(255, 127, 41, 0.25);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 10px rgba(255, 127, 41, 0.2);
      }

      &:disabled {
        background: linear-gradient(45deg, #6c757d, #adb5bd);
        cursor: not-allowed;
      }
    }
  }

  .summary-sidebar {
    position: sticky;
    top: 20px;

    .card {
      border-radius: 15px;

      .card-body {
        padding: 2rem;
      }
    }

    .summary-title {
      margin-bottom: 1.5rem;
      color: #d35400;
      font-weight: 600;
      display: flex;
      align-items: center;

      svg {
        margin-right: 1rem;
        color: #d35400;
      }
    }

    .summary-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      border-bottom: 1px solid #f0f2f5;

      &:last-child {
        border-bottom: none;
      }

      .label {
        color: #6c757d;
        font-weight: 500;
      }

      .value {
        color: #d35400;
        font-weight: 600;
        font-size: 1.1rem;

        &.total {
          color: #d35400;
          font-size: 1.3rem;
        }
      }
    }
  }

  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(5px);

    .spinner {
      width: 50px;
      height: 50px;
      border: 3px solid rgba(255, 127, 41, 0.1);
      border-radius: 50%;
      border-top-color: #ff7f29;
      animation: spin 1s linear infinite;
      margin-bottom: 1rem;
    }

    .loading-text {
      color: #ff7f29;
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: 1rem;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .card .card-body {
      padding: 1.5rem;
    }

    .registration-header {
      h2 {
        font-size: 1.5rem;
      }
    }

    .summary-sidebar {
      position: static;
      margin-top: 2rem;
    }
  }
}

.banner {
  border-radius: 15px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.08);
}