.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 2rem;

  .login-logo {
    margin-bottom: 1rem;
    text-align: center;

    img {
      height: 223px;
    }
  }

  h2 {
    margin-bottom: 2rem;
    color: #343a40;
    text-align: center;
  }

  form {
    width: 100%;
    max-width: 400px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  }

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 100%;

    label {
      margin-bottom: 0.5rem;
      color: #495057;
    }

    input {
      padding: 1rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:focus {
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
  }

  button {
    padding: 1rem 1.5rem;
    background-color: #ff7849;
    color: white;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #d65a30;
    }
  }
}