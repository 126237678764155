.registrations-page {
  padding: 1.5rem;

  .page-title {
    color: #495057;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .search-box {
    position: relative;

    input {
      padding-left: 2.5rem;
      border-radius: 4px;
      border: 1px solid #ced4da;
    }

    .search-icon {
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      color: #6c757d;
    }
  }

  .btn-group {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 1rem;
      
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .registrations-table {
    th {
      background-color: #f8f9fa;
      border-bottom: 2px solid #dee2e6;
      color: #495057;
      font-weight: 600;
      white-space: nowrap;
    }

    td {
      vertical-align: middle;
      color: #495057;
    }

    .badge {
      padding: 0.5em 0.75em;
      font-weight: 500;
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }

      &:hover {
        color: #e85c41;
      }
    }

    .dropdown-menu {
      min-width: 160px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);

      .dropdown-item {
        padding: 0.5rem 1rem;
        
        &:hover {
          background-color: #f8f9fa;
        }

        &.text-danger:hover {
          background-color: #fff5f5;
        }
      }
    }
  }

  // Date picker customization
  .react-datepicker-wrapper {
    width: calc(50% - 0.5rem);

    .form-control {
      width: 100%;
    }
  }

  // Advanced filters
  .form-group {
    margin-bottom: 1rem;

    label {
      font-weight: 500;
      color: #495057;
      margin-bottom: 0.5rem;
    }

    select.form-control {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }
}
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 20px; /* Adjust position as needed */
    left: 20px; /* Adjust position as needed */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}