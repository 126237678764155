.cities-management {
  .modal-body {
    max-height: 70vh;
    overflow-y: auto;
    padding: 20px;
  }

  .ticket-types-checkbox-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    
    .form-check {
      display: flex;
      align-items: center;
      
      .form-check-input {
        margin-right: 10px;
      }

      .form-check-label {
        margin-left: 5px;
      }
    }
  }

  table {
    th, td {
      vertical-align: middle;
      padding: 12px;
    }
  }

  // Button spacing
  .btn {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  // Form group spacing
  .form-group {
    margin-bottom: 20px;

    label {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  }

  // New styles for city photo preview
  .city-photo-preview {
    max-width: 200px;
    max-height: 200px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 15px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .modal-dialog {
      max-width: 95%;
      margin: 1.75rem auto;
    }

    .ticket-types-checkbox-list {
      grid-template-columns: 1fr;
    }
  }
}
