header {
  color: white;
  padding: 5px;
  text-align: center;
  border-bottom: 5px solid #f26f25;

  .header {
    margin: 0;
    border-bottom: 5px solid #ffcc00;
  }

  nav {
    margin-top: 0px;

    .nav-links {
      list-style-type: none;
      padding: 0;

      li {
        display: inline;
        margin: 0 15px;

        a {
          color: white;
          text-decoration: none;
          transition: color 0.3s;

          &:hover {
            color: #ffcc00;
          }
        }
      }
    }
  }
}
