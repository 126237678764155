// Sidebar Shadow
.app-sidebar {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
}

// Header Shadow
.app-header.header-shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
              0 1px 3px rgba(0, 0, 0, 0.08);
}

// Responsive Adjustments
@media (max-width: 991.98px) {
  .app-sidebar {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .app-header.header-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
