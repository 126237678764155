@import '../../assets/base.scss';

.registration-success-page {
  min-height: 100vh;
  padding: 2rem 0 0;
  background: 
    linear-gradient(120deg, rgba(255, 127, 41, 0.05) 0%, rgba(255, 107, 0, 0.05) 100%),
    radial-gradient(circle at 50% 0%, rgba(255, 127, 41, 0.1) 0%, transparent 50%),
    linear-gradient(60deg, #ffffff 0%, #fff5ee 100%);
  background-attachment: fixed;

  .success-banner {
    background: linear-gradient(135deg, #ff6b00, #ff7f29);
    color: white;
    border: none;
    border-radius: 12px;
    box-shadow: 0 8px 30px rgba(255, 107, 0, 0.15);
    padding: 2rem;
    text-align: center;
    margin-bottom: 2rem;
    
    .fa-icon {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      color: rgba(255, 255, 255, 0.9);
    }

    h2 {
      font-weight: 700;
      margin-bottom: 1rem;
      font-size: 2rem;
      text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    }
  }

  .main-card {
    border-radius: 12px;
    border: none;
    box-shadow: 
      0 4px 15px rgba(255, 127, 41, 0.08),
      inset 0 -4px 0 rgba(255, 127, 41, 0.2);
    margin-bottom: 1.5rem;
    backdrop-filter: blur(10px);
    background: linear-gradient(to right, #ffffff, #fff8f3);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      background: linear-gradient(to bottom, #ff7f29, #ff6b00);
    }
    
    .card-body {
      padding: 1.5rem 2rem;

      h5 {
        color: #d35400;
        font-weight: 600;
        margin: 2rem 0 1.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 2px solid rgba(255, 127, 41, 0.1);
      }
    }

    .event-info {
      background: rgba(255, 127, 41, 0.03);
      border-radius: 10px;
      padding: 1.5rem;
      margin: 1rem 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.2rem;
      border: 1px solid rgba(255, 127, 41, 0.1);

      .info-item {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        color: #666;
        padding: 0.5rem;
        border-radius: 8px;
        transition: all 0.3s ease;

        svg {
          color: #ff7f29;
          font-size: 1.2rem;
          opacity: 0.8;
        }

        strong {
          color: #d35400;
          margin-right: 0.5rem;
        }

        &:hover {
          background: rgba(255, 127, 41, 0.05);
          transform: translateX(5px);
        }
      }
    }
  }

  .tickets-container {
    display: grid;
    grid-gap: 2rem;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }

  .ticket-card-wrapper {
    margin-bottom: 2rem;
    
    .ticket-card {
      border-radius: 12px;
      border: none;
      box-shadow: 
        0 4px 15px rgba(255, 127, 41, 0.08),
        inset 0 -4px 0 rgba(255, 127, 41, 0.2);
      background: linear-gradient(to right, #ffffff, #fff8f3);
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        // background: linear-gradient(to bottom, #ff7f29, #ff6b00);
      }

      .ticket-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        .ticket-title {
          h5 {
            color: #d35400;
            font-weight: 600;
            margin-bottom: 0.5rem;
          }

          .event-date {
            color: #666;
            font-size: 0.9rem;
          }
        }

        .download-btn {
          color: #ff6b00;
          transition: all 0.3s ease;
          
          &:hover {
            color: #ff7f29;
            transform: scale(1.1);
          }
        }
      }

      .ticket-content {
        padding: 1rem;

        .ticket-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 1.5rem;

          .participant-details {
            flex: 1;

            h6 {
              font-size: 1.1rem;
              margin-bottom: 0.5rem;
              color: #2c3e50;
              font-weight: 600;
            }

            .ticket-type {
              display: inline-block;
              padding: 0.35rem 0.75rem;
              background: #ffecdb;
              color: #ff6b00;
              border-radius: 50px;
              font-size: 0.9rem;
              margin-bottom: 1rem;
            }

            p {
              margin-bottom: 0.5rem;
              color: #6c757d;
              font-size: 0.95rem;

              &:last-child {
                margin-bottom: 0;
              }

              strong {
                color: #495057;
                margin-right: 0.25rem;
              }
            }
          }

          .ticket-qr {
            background: white;
            padding: 0.75rem;
            border-radius: 12px;
            box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
          }
        }
      }

      .ticket-footer {
        padding: 1rem 1.5rem;
        background: #f8f9fa;
        border-top: 1px solid #e9ecef;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ticket-number {
          font-family: monospace;
          color: #6c757d;
          font-size: 0.9rem;
        }

        .event-logo {
          height: 30px;
          width: auto;
        }
      }
    }
  }

  .social-share-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;
    padding: 1.5rem;
    background: rgba(211, 84, 0, 0.02);
    border-radius: 12px;
    border: 1px solid rgba(211, 84, 0, 0.1);

    .btn {
      padding: 0.75rem 1.5rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      transition: all 0.3s ease;
      background: white;
      border: 1px solid rgba(211, 84, 0, 0.2);
      color: #d35400;

      &:hover {
        background: rgba(211, 84, 0, 0.05);
        border-color: rgba(211, 84, 0, 0.3);
        transform: translateY(-2px);
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }

  .footer-actions {
    margin-top: 3rem;
    text-align: center;
    padding-bottom: 2rem;

    .btn-primary {
      background: linear-gradient(135deg, #ff7f29, #ff6b00);
      border: none;
      padding: 1rem 3rem;
      font-weight: 600;
      font-size: 1.1rem;
      border-radius: 50px;
      box-shadow: 0 4px 15px rgba(255, 127, 41, 0.2);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 20px rgba(255, 127, 41, 0.3);
      }
    }
  }

  .qr-modal {
    .modal-content {
      border-radius: 15px;
      border: none;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    }

    .modal-header {
      background: #f8f9fa;
      border-bottom: none;
      padding: 1.5rem;
    }

    .modal-body {
      padding: 2rem;
      text-align: center;

      .qr-details {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid #e9ecef;

        h5 {
          color: #2c3e50;
          margin-bottom: 1rem;
        }

        p {
          color: #6c757d;
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  @media print {
    .no-print {
      display: none;
    }

    background: none;
    padding: 0;

    .main-card {
      box-shadow: none;
      border: 1px solid #dee2e6;
    }

    .ticket-card {
      page-break-inside: avoid;
      margin-bottom: 2rem;
      border: 1px solid #dee2e6 !important;
      box-shadow: none !important;
    }
  }
}
